import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, from, of } from 'rxjs';
import { DataStorageService } from './data.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RandomUniqueIdService } from './randomUniqueId.service';

@Injectable({
  providedIn: 'root'
})
export class CashingService {
  cashingUrl = environment.ENDPOINTS.CLIENTS_URL
  constructor(private httpClient: HttpClient, private cacheStorageService: DataStorageService,
    private randomUniqueIdService: RandomUniqueIdService,) { }

  getCashings(companyId: number, page: number, size: number, sort: any, status?: any, advncedFilters?, context?: boolean) {
    status = status && status.length == 1 ? status[0] + '' : null;
    const params = { page, size, sort: [sort[0].prop, sort[0].direction], status, ...advncedFilters };
    let queryParms = new HttpParams();
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        queryParms = queryParms.append(key, value + "");
      }
    }
    const req = this.cashingUrl + `/${companyId}/cash-receipts`;
    // return this.httpClient.get<any>(req,{ observe: 'response', params:queryParms});
    // if (!navigator.onLine && !context) {
    //   return from(this.cacheStorageService.getAllItems('cachings')).pipe(
    //     catchError(() => this.httpClient.get<any>(req, { observe: 'response', params: queryParms }))
    //   );
    // }

    if (!navigator.onLine && !context) {
      const storedData$ = from(this.cacheStorageService.getAllItems('cachings')).pipe(
        catchError(() => this.httpClient.get<any>(req, { observe: 'response', params: queryParms }))
      );
      const addedData$ = from(this.cacheStorageService.getAllItems('cashingsAdded')).pipe(
        catchError(() => this.httpClient.get<any>(req, { observe: 'response', params: queryParms }))
      );
      const concatenated = forkJoin([storedData$, addedData$]).pipe(
        map(([storedData, addedData]) => {
          return [...storedData, ...addedData] as any;
        }
        )
      );
      return concatenated;
    }
    return this.httpClient.get<any>(req, { observe: 'response', params: queryParms }).pipe(
      switchMap(response => {
        // this.cacheStorageService.setItem(req+queryParms.toString(), response);
        return of(response);
      })
    );
  }

  getCashingById(companyId, cashing) {
    const req = this.cashingUrl + `/${companyId}/cash-receipts/${(cashing instanceof Object) ? cashing.id : cashing}`;
    // return this.httpClient.get<any>(req, { observe: 'response' });
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(cashing.id, cashing.offlineReference ? 'cashingsAdded' : 'cachings')).pipe(
        catchError(() => this.httpClient.get<any>(req, { observe: 'response' }))
      );
    }
    return this.httpClient.get<any>(req, { observe: 'response' }).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  deleteCaching(companyId, type, id) {
    const req = this.cashingUrl + `/${companyId}/cash-receipts/${type}/${id}`;
    return this.httpClient.delete<any>(req);
  }

  createCashing(companyId, invoiceAmount, interestType?: any): Observable<any> {
    const req = this.cashingUrl + `/${companyId}/cash-receipts/${interestType}`;
    // return this.httpClient.post<any>(req, invoiceAmount);
    if (!navigator.onLine) {
      const bodyOffline = {
        ...invoiceAmount,
        id: new Date().toISOString(),
        interestType: interestType,
        offlineReference: this.randomUniqueIdService.generateUniqueId('ENC-'),
      }
      this.cacheStorageService.addItem([bodyOffline], 'cashingsAdded');
      return from(this.cacheStorageService.getItem(bodyOffline.id, 'cashingsAdded', true)).pipe(
        catchError(() =>
          this.httpClient.post<any>(req, invoiceAmount))
      );
    }
    const str = JSON.stringify(invoiceAmount);
    const fd = new FormData();
    fd.append(
      "cash_receipt",
      new File([str], "cash_receipt.json", { type: "application/json" })
    );
    if (invoiceAmount.attachment) {
      fd.append("attachment", invoiceAmount.attachment);
    }
    return this.httpClient.post<any>(req, fd).pipe(
      switchMap(response => {
        return of(response);
      })
    );
  }

  updateStatus(companyId, id, status): Observable<any> {
    const req = this.cashingUrl + `/${companyId}/cash-receipts/${id}/update-status?status=${status}`;
    return this.httpClient.post<any>(req, status);
  }

  updateCashing(companyId, id, invoiceAmount: FormData, interestType?: any): Observable<any> {
    const req = this.cashingUrl + `/${companyId}/cash-receipts/${interestType}/${id}`;
    return this.httpClient.put<any>(req, invoiceAmount);
  }
}
